import React, { useState, useEffect } from 'react';
import { TextField, List, ListItem, ListItemText, CssBaseline, Container, Typography, Box, Button, Grid, Link, Stepper, Step, StepLabel, CircularProgress } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import Logo from '../../../assets/images/logo.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { footerLinks } from '../Shared/footerLinks';
import { theme, style, config, StyledRadio } from '../../../config/settings.jsx';
import { steps } from '../../../config/steps';
import { api } from '../../../config/apis.jsx';
import { getTheme } from '../../../config/theme';

const Finished = () => {
  const [subdomain, setSubdomain] = useState('');
  const [isLoadingTheme, setIsLoadingTheme] = useState(false);
  const [theme, setTheme] = useState({});
  const [assetsPath, setAssetsPath] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingYes, setIsLoadingYes] = useState(false);
  const [isLoadingNo, setIsLoadingNo] = useState(false);
  const navigate = useNavigate();
  const [statusMessage, setStatusMessage] = useState('Checking your bank connection and authorising accounts');
  const [pageTitle, setPageTitle] = useState('Thankyou');
  const location = useLocation();
  const cdrid = localStorage.getItem('cdrid');
  const [stepperStep, setStepperStep] = useState(4);
{/* Host name identification and configassets loading */}
useEffect(() => {
  setIsLoadingTheme(true);
  let subdomain;
  
  const loadConfigAndAssets = async () => {
  const hostname = window.location.hostname; 
  const parts = hostname.split('.'); 
  if (parts.length > 2 && parts[1] === 'sipora' && parts[2] === 'io') {
    subdomain = parts[0];
    setSubdomain(subdomain); 
  } else {
    subdomain = 'default';
  }
  localStorage.setItem('host', subdomain);

  const settings = await import(`../../../config/${subdomain}/settings.jsx`);
      setTheme(settings.theme);  // Assuming default export
      setAssetsPath(settings.logoURL);  // Dynamic asset folder
  
  console.log(settings)
  setIsLoadingTheme(false);
  };

  loadConfigAndAssets();

}, []);


  const handleYes = async () => {

    setIsLoadingYes(true);
    try {
        // Replace this with your API call or function
        const response = await axios.post(api.onboardingComplete, {
            cdrid: cdrid, syncBeginningOfMonth: true
        },
        { headers: { 'Content-Type': 'application/json',
           apikey: config.apikey,
            tenant: config.tenant
         } }
        );
        navigate('/dashboard');
    } catch (error) {
        console.error('API Error:', error);
    }
};

const handleNo = async () => {
  setIsLoadingNo(true);
  try {
      const response = await axios.post(api.onboardingComplete, {
        cdrid: cdrid, syncBeginningOfMonth: false
      },
      { headers: { 'Content-Type': 'application/json',
          apikey: config.apikey,
            tenant: config.tenant
       } });
     navigate('/dashboard');
  } catch (error) {
      console.error('API Error:', error);
  }
};


return (
    <ThemeProvider theme={getTheme}>
    <CssBaseline>
    <Container component="main" maxWidth="xs">
    {isLoadingTheme ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mt: 20 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
      <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2} >
      <Grid container justifyContent="center" mb={2}>
         <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={6} style={{ width: '100%'}} >
        <Stepper activeStep={stepperStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
        </Box>
      
      <Typography variant="h4" component="h2" align="center" mt={2} sx={{fontWeight: 'bold'}} gutterBottom>
      {pageTitle}
      </Typography>
      
      <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={6} style={{ width: '100%'}}>
      <Typography variant="body2" component="h2" align="center"  gutterBottom>
       Onboarding has been complete:
      </Typography>
      <Typography variant="body2" component="h2" align="center"  gutterBottom>
      Would you like to start your round-ups as of the 1st of this month?
      </Typography>
      </Box>
      <Box display="flex" width="100%" justifyContent="space-between">
           <Box display="flex"  width="49%" >
           <Button 
            onClick={handleNo}
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
             >
            <Typography >
            {isLoadingNo ? 'Loading...' : 'No'}
            </Typography>
         
          </Button>

           </Box>
           <Box display="flex"  width="49%" >
           <Button 
            onClick={(handleYes)}
            type="button"
            fullWidth
            variant="contained"
            color="switchColor"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: '100%' }}
            disabled={isLoading}
          >
            <Typography >
            {isLoadingYes ? 'Loading...' : 'Yes'}
            </Typography>
         
          </Button>

           </Box></Box>

      
    
      </Grid>
      



                    
      {footerLinks}
        </Box></>)}
    </Container>
    </CssBaseline> </ThemeProvider>
  );
};

export default Finished;

export {};