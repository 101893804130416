import React, { useState, useEffect } from 'react';
import { TextField, List, ListItem, ListItemText, Container, Typography, Box, Button, Grid, Link, CircularProgress } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import Logo from '../../../assets/images/logo_a.svg';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { footerLinks } from '../Shared/footerLinks';
import { theme, style, config, StyledRadio, CustomListItem } from '../../../config/settings.jsx';
import { getTheme } from '../../../config/theme.jsx';
import { useLocation } from 'react-router-dom';
import { api } from '../../../config/apis.jsx';



type Club = {
  _id: string;
  name: string;
  parent_club: string;
};

const ClubSelect = () => {
  const [subdomain, setSubdomain] = useState('');
  const [isLoadingTheme, setIsLoadingTheme] = useState(false);
  const [isLoadingClubs, setIsLoadingClubs] = useState(false);
  const [assetsPath, setAssetsPath] = useState('');
  const [clubs, setClubs] = useState<Club[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClubId, setSelectedClubId] = useState<string | null>(null);
  const sourceClub = localStorage.getItem('sourceClub');
  const navigate = useNavigate();
  const location = useLocation();
  const [parentClubId, setParentClubId] = useState('');

  console.log('Parent Club ID:', parentClubId);

{/* Host name identification and configassets loading */}
useEffect(() => {
  const loadConfigAndAssetsAndFetchClubs = async () => {
    setIsLoadingTheme(true);
    let subdomain;
    
    const loadConfigAndAssets = async () => {
      const hostname = window.location.hostname; 
      const parts = hostname.split('.'); 
      if (parts.length > 2 && parts[1] === 'sipora' && parts[2] === 'io') {
        subdomain = parts[0];
        setSubdomain(subdomain); 
      } else {
        subdomain = 'default';
      }
      localStorage.setItem('host', subdomain);
  
      const settings = await import(`../../../config/${subdomain}/settings.jsx`);
      setAssetsPath(settings.logoURL);  // Dynamic asset folder
      if (settings.parentClubId) {
        setParentClubId(settings.parentClubId);
      }
  
      console.log(settings.parentClubId);
    };

    const fetchClubs = async () => {
      setIsLoadingClubs(true);
      try {
        const response = await axios.get(api.retrieveClubs, {
          headers: {
            'Content-Type': 'application/json',
            apikey: config.apikey,
            tenant: config.tenant
          }
        });
    
        let allClubs = response.data.clubs;
        
        // Explicitly check if parentClubId exists and filter based on it
        let filteredParentClubs = allClubs;
        if (parentClubId) {
          filteredParentClubs = allClubs.filter((club: Club) => {
            // Ensure parent_club exists and matches the parentClubId
            return club.parent_club && club.parent_club === parentClubId;
          });
        }
    
        setClubs(filteredParentClubs); // Update the clubs with filtered ones
      } catch (error) {
        console.error('Error fetching clubs:', error);
      } finally {
        setIsLoadingClubs(false); // Stop loading
      }
    };

    try {
      // Load theme and config first
      await loadConfigAndAssets();

      // Then fetch clubs
      await fetchClubs();
    } catch (error) {
      console.error('Error loading theme and fetching clubs:', error);
    } finally {
      setIsLoadingTheme(false); // Stop loading theme and data
    }
  };

  // Call the combined function when the component mounts
  loadConfigAndAssetsAndFetchClubs();

}, [parentClubId]); // Dependency on parentClubId (will be set in loadConfigAndAssets)

  
  let filteredClubs = clubs;

 if (searchTerm) {
      filteredClubs = clubs.filter(club =>
        club.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

  const handleClubSelect = (clubId: string) => {
    setSelectedClubId(clubId);
    navigate('/register2', { state: { selectedClubId: clubId } });
  };

  return (
    <Container component="main" maxWidth="xs">
        {isLoadingTheme || isLoadingClubs ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mt: 20 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
      <Box display="flex" flexDirection="column" alignItems="center" mt={8} p={2} >
        <Grid container justifyContent="center" mb={2}>
          <Grid item xs={12} justifyContent="center">
            <Box display="flex" justifyContent="center">
              <img src={assetsPath} alt="Logo" style={{ width: '70%', height: 'auto'}} />
            </Box>
          </Grid>
        </Grid>

        <Typography variant="h6" component="h2" align="center" gutterBottom>
          Choose an organisation to support 
        </Typography>
        <Box display="flex" alignItems="center" mb={2} style={{ width: '100%'}}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            placeholder="Search for a club here..."
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '16px', // Apply border radius
              },
          }}
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
            }}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </Box>
        <Box style={{ maxHeight: '300px', overflowY: 'auto' }}>
          <List>
            {filteredClubs.map(club => (
              <CustomListItem 
                key={club._id}
                 onClick={() => handleClubSelect(club._id)}
                selected={selectedClubId === club._id}
              >
                <ListItemText primary={club.name} />
              </CustomListItem>
            ))}
          </List>
        </Box>

        <Grid item xs={12} justifyContent="center">
          <Box display="flex" justifyContent="center" p={1}>
            <Typography variant="body2"> Already have an account? 
              <Link component={RouterLink} to="/" variant="body2" sx={{ ml: 0.5, textDecoration: 'none' }}> Login
              </Link>
            </Typography>
          </Box>
        </Grid>

       {footerLinks}
      </Box>
      </>)}
    </Container>
  );
};

export default ClubSelect;

export {};
