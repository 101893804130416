// src/components/screens/ClubList.tsx
import React, { useState, useEffect } from 'react';
import { TextField, List, ListItem, ListItemText, ListItemIcon, Container, Typography, Box, Button, Grid, Link, Stepper, Step, StepLabel, StepIconProps, CircularProgress } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { footerLinks } from '../Shared/footerLinks';
import { theme, style, config, StyledRadio } from '../../../config/settings.jsx';
import { steps } from '../../../config/steps';
import { api } from '../../../config/apis.jsx';
import { getTheme } from '../../../config/theme';
import CheckIcon from '@mui/icons-material/Check';

const LaunchConsent = () => {
  const [subdomain, setSubdomain] = useState('');
  const [isLoadingTheme, setIsLoadingTheme] = useState(false);
  const [theme, setTheme] = useState({});
  const [assetsPath, setAssetsPath] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [consentLink, setConsentLink] = useState('');
  const cdrid = localStorage.getItem('cdrid');
  const apikey = 'F3tDuot6*otiwCp$aRW9ahcPKHkMUwxUUE&tW4#vg84%YV7Ud56s@dVhCzoZrkBpRjwF!96Pp7Z*PJZFZuSC5ZesHScYNtQxij%A^Ta$SNA@QFd*!HZ2k4h@JH3cZYtf';

  {/* Host name identification and configassets loading */}
useEffect(() => {
  setIsLoadingTheme(true);
  let subdomain;
  
  const loadConfigAndAssets = async () => {
  const hostname = window.location.hostname; 
  const parts = hostname.split('.'); 
  if (parts.length > 2 && parts[1] === 'sipora' && parts[2] === 'io') {
    subdomain = parts[0];
    setSubdomain(subdomain); 
  } else {
    subdomain = 'default';
  }
  localStorage.setItem('host', subdomain);

  const settings = await import(`../../../config/${subdomain}/settings.jsx`);
      setAssetsPath(settings.logoURL);  // Dynamic asset folder
  
  console.log(settings)
  setIsLoadingTheme(false);
  };

  loadConfigAndAssets();

}, []);


  const fetchConsent = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(api.consent,
      {cdrid: cdrid},
      { headers: { 'Content-Type': 'application/json',
          apikey: config.apikey,
            tenant: config.tenant
       } }); // Replace with your actual API endpoint
      const consentLink = response.data.consentLink;
      window.location.href = consentLink // Adjust based on your API response structure
    } catch (error) {
      console.error('Error creating consent Link:', error);
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <ThemeProvider theme={getTheme}>
    <Container component="main" maxWidth="xs">
 
      <Box display="flex" flexDirection="column" alignItems="center" mt={4} p={2} >
      <Grid container justifyContent="center" mb={2}>
  
      
           <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={6} style={{ width: '100%'}} >
        <Stepper activeStep={1} alternativeLabel>
        {steps.map((label) => (
          <Step key={label} >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
        </Box>
      
        <Typography variant="h4" component="h2" align="center" mb={2} sx={{fontWeight: 'bold'}} gutterBottom>
        Welcome
      </Typography>
      
      <Box display="flex" flexDirection="column" alignItems="left" mb={2} style={{ width: '100%'}}>
      <Typography variant="body" component="h2" align="left"  sx={{fontWeight: 'bold'}}>
        Let's set up your accounts:
      </Typography>
      <Typography variant="subtitle1" component="div" align="left" mb={2} gutterBottom>
 <List>
    <ListItem>
    <ListItemIcon>
      <CheckIcon style={{ color: '#47b3b5' }} /> {/* You can change the color */}
    </ListItemIcon>
    <ListItemText primary="Tranasction account to track round-ups" />
      </ListItem>
    <ListItem>
    <ListItemIcon>
      <CheckIcon style={{ color: '#47b3b5' }} /> {/* You can change the color */}
    </ListItemIcon>
    <ListItemText primary="Funding account for micro debits Monday, Wednesday and Friday" />
      </ListItem>
    
  </List>
   </Typography>
  <Typography variant="body" component="h2" align="left"  sx={{fontWeight: 'bold'}}>
       To continue, you will need:
      </Typography>
      <Typography variant="subtitle1" component="div" align="left" mb={2} gutterBottom>
      <List>
  <ListItem>
    <ListItemIcon>
      <CheckIcon style={{ color: '#47b3b5' }} /> {/* You can change the color */}
    </ListItemIcon>
    <ListItemText primary="Internet Banking CRN - Customer Reference Number" />
  </ListItem>
  <ListItem>
    <ListItemIcon>
      <CheckIcon style={{ color: '#47b3b5' }} />
    </ListItemIcon>
    <ListItemText primary="Internet Banking App - they'll send you a code" />
  </ListItem>
</List></Typography>
   
      </Box>
       
      </Grid>
      
   
          <Button
            onClick={fetchConsent}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, borderRadius: 3, width: "100%"  }}
            disabled={isLoading}

                  
          >
            {isLoading ? 'Loading...' : 'Continue'}
         
          </Button>


          { footerLinks }
        </Box>
    </Container> </ThemeProvider>
  );
};

export default LaunchConsent;

export {};